@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  padding: 0;
  top: 0 !important;
  /* background-color: #000; */
  color: #eee;
  /* font-family: Poppins; */
  font-size: 12px;
    font-family: Inter !important;
}
.caruselhead{
  top: 0;
  /* padding-top: 10px; */
}
.caruselhead .header {
  display: flex !important;
  align-items: center;
}
a {
  text-decoration: none;
}
.buttons{
  
}
.arrows{
  /* padding: 20px; */
  /* margin-left: 40px !important; */
}
header {
  width: 1040px;
  max-width: 100%;
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
}

/* header a {
  color: #eee;
  margin-right: 40px;
} */
/* carousel */
.carousel {
  height: 100vh;
  margin-top: -50px;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.carousel .list .item {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}
.carousel .list .item img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}
.carousel .list .item .content {
  position: absolute;
  top: 20%;
  width: 1140px;
  max-width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 30%;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
}
.carousel .list .item .author {
  font-weight: bold;
  letter-spacing: 10px;
}
.carousel .list .item .title{
  font-size: 5em;
  font-family: Inter !important;
  font-weight: bold;
  line-height: 1.3em;
}

.carousel .list .item .topic {
  font-size: 3em;
  font-family: Inter !important;
  font-weight: bold;
  line-height: 1.0em;
}
.carousel .list .item .des {
  /* color: #f1683a; */
    font-family: Inter !important;
}
.carousel .list .item .buttons {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 40px;
}
.carousel .list .item .buttons button {
  border: none;
  background-color: #eee;
  letter-spacing: 3px;
  font-family: Poppins;
  font-weight: 700;
  color: black;
}
.carousel .list .item .buttons button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}
/* thumbail */
.thumbnail {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;
}
.thumbnail .item {
  width: 150px;
  height: 220px;
  flex-shrink: 0;
  position: relative;
}
.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.thumbnail .item .content {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}
.thumbnail .item .content .title {
  font-weight: 500;
  font-size: 15px;
}
.thumbnail .item .content .description {
  font-weight: 300;
  width: 50px;
}
.des{
  width: 80%;
}
/* arrows */
.arrows {
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.arrows button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee4;
  border: none;
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  transition: 0.5s;
}
.arrows button:hover {
  background-color: #fff;
  color: #000;
}

/* animation */
.carousel .list .item:nth-child(1) {
  z-index: 1;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons {
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s 1s linear 1 forwards;
}
@keyframes showContent {
  to {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
.carousel .list .item:nth-child(1) .content .title {
  animation-delay: 1.2s !important;
}
.carousel .list .item:nth-child(1) .content .topic {
  animation-delay: 1.4s !important;
}
.carousel .list .item:nth-child(1) .content .des {
  animation-delay: 1.6s !important;
}
.carousel .list .item:nth-child(1) .content .buttons {
  animation-delay: 1.8s !important;
}
/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  border-radius: 30px;
  animation: showImage 0.5s linear 1 forwards;
}
@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
  overflow: hidden;
  animation: showThumbnail 0.5s linear 1 forwards;
}
.carousel.prev .list .item img {
  z-index: 100;
}
@keyframes showThumbnail {
  from {
    width: 0;
    opacity: 0;
  }
}
.carousel.next .thumbnail {
  animation: effectNext 0.5s linear 1 forwards;
}

@keyframes effectNext {
  from {
    transform: translateX(150px);
  }
}

/* running time */

.carousel .time {
  position: absolute;
  z-index: 1000;
  width: 0%;
  height: 3px;
  background-color: #f1683a;
  left: 0;
  top: 0;
}

.carousel.next .time,
.carousel.prev .time {
  animation: runningTime 3s linear 1 forwards;
}
@keyframes runningTime {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

/* prev click */

.carousel.prev .list .item:nth-child(2) {
  z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}
@keyframes outFrame {
  to {
    width: 150px;
    height: 220px;
    bottom: 50px;
    left: 50%;
    border-radius: 20px;
  }
}

.carousel.prev .thumbnail .item:nth-child(1) {
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail 0.5s linear 1 forwards;
}
.carousel.next .arrows button,
.carousel.prev .arrows button {
  pointer-events: none;
}
.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
  animation: contentOut 1.5s linear 1 forwards !important;
}

/* @keyframes contentOut {
  to {
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}
@media screen and (max-width: 678px) {
  .carousel .list .item .content {
    padding-right: 0;
  }
  .carousel .list .item .content .title {
    font-size: 30px;
  }
} */
/* ------------------------------ */
@media screen and (max-width: 1200px) {
  .carousel .list .item .content {
    padding: 15px;
  }
  .carousel .content .title {
    font-size: 35px;
  }
  .carousel .content .topic {
    font-size: 28px !important;
    
  }
  .thumbnail .item .content .title {
  font-weight: 500;
  font-size: 20px !important;
}

  .carousel .content .des {
    font-size: 16px;
  }
  
  .carousel .thumbnail .item img {
    width: 90%;
  }
}

@media screen and (max-width: 992px) {
  .carousel .list .item .content {
    padding: 10px;
  }

  .carousel .thumbnail .item img {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .carousel .list .item .content {
    padding: 8px;
  }
  .carousel .content .title {
    font-size: 25px;
  }
  .carousel .content .topic {
    font-size: 19px !important;
    
  }

  .carousel .content .des {
    font-size: 17px;
  }

  .carousel .thumbnail .item img {
    width: 90%;
  }

  .carousel .arrows button {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .carousel .list .item .content {
    padding: 5px;
  }
 /* .caruselhead .header .pp{
  display: none;
 } */
  .carousel .content .title {
    font-size: 18px;
  }
  .carousel .content .topic {
    font-size: 14px !important;
  }
   .thumbnail .item .content .title {
  font-weight: 200;
  font-size: 15px !important;
  }

 .navbarin{
  /* display: flex;
  align-items: center;
  flex-wrap: wrap; */
 }
 .book{
  color:  white !important;
  left: 50%;
  font-size: 8rem !important;
  font-family: Georgia, 'Times New Roman', Times, serif;
 }
  .carousel .content .des {
    font-size: 10px;
  }

  .carousel .thumbnail .item .content {
   margin-top: 200px ;
    width: 60%;
    height: 20% !important;
  }
  .carousel .thumbnail .item  .title{
   display: none !important;
  }


  .carousel .arrows button {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
   .carousel .thumbnail .item{
    /* top: 20%; */
  }
  .carousel .content .title {
    margin-top: 30px;
    font-size: 30px !important;
  }
  .carousel .content .topic {
    font-size: 20px;
    
  }
    .carousel .content .des {
    font-size: 12px;
    display: none;
  }

  .carousel .thumbnail .item img {
   margin-top: 200px ;
    width: 90%;
    height: 30%;
  }
  .carousel .thumbnail .item  .title{
   display: none;
  }

}
@media screen and (max-width: 400px) {
  .carousel .list .item .content {
    padding: 3px;
  }
 .caruselhead .header .pp{
  /* display: none; */
  font-size: 15px !important;
  padding-left: 10px !important;
  margin-left: 30px !important;
 }

  .carousel .content .title {
    margin-top: 30px;
    font-size: 20px !important;
  }
  .carousel .content .topic {
    font-size: 6px;
    
  }

  .carousel .content .des {
    font-size: 12px;
    display: none;
  }
  .carousel .content .buttons {
    font-size: 12px;
    display: none;
  }

  .carousel .thumbnail .item{
    /* top: 20%; */
  }

  .carousel .thumbnail .item img {
   margin-top: 200px ;
    width: 90%;
    height: 30%;
  }
  .carousel .thumbnail .item  .title{
   display: none;
  }

  .carousel .arrows button {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}