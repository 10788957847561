.icon {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  /* color: black !important; */
  margin-bottom: 6px !important;
}
/* .navbar-light .navbar-toggler-icon{
  background-image: none;
} */
#navbarScrollingDropdown {
  color: rgb(64, 56, 56);
  background-image: none;
}
.navbar {
  margin-bottom: 0px;
  margin: 0;
  /* padding: 0; */
  bottom: 0;
  font-family: Inter !important;
  color: #343a40;
}
.navbar .textmehar {
  font-family: cinzel !important;
}

.background {
  /* background: linear-gradient(90deg, #FBCEB1, #DAA520); */
  background: linear-gradient(90deg, white, white);
  /* background: linear-gradient(90deg, #FBCEB1 , #4ECDC4); */

  /* background: linear-gradient(90deg, #ee4fd9, #A16BFE); */
  /* background: linear-gradient(90deg, #E13680, #A43AB2); */
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Adjust as needed */
  /* Additional styles for sticky navbar */
}
@media screen and (max-width: 800px) {
  .textmehar {
    font-size: 16px !important;
  }
  .listitem {
    width: 30px !important;
    margin-bottom: 30px;
  }
  /* Header.css */

  .toggle {
    z-index: 9999999;
    /* color: black !important; */
    background-image: url("../../assets/list.jpeg"); 
    /* Replace with your image path */
    background-size: cover; /* Make sure the image covers the entire button */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    visibility: visible;
    border: none;
    margin-right: 18px !important;
    width: 25px; /* Adjust the size as needed */
    height: 25px; /* Adjust the size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Adds a pointer cursor on hover */
  }

  /* Make sure the icon is centered inside the button */
  .toggle .fa-list {
    font-size: 17px;
    color: white; /* Adjust the color as needed */
  }
}

@media screen and (max-width: 600px) {
  .textmehar {
    font-size: 12px !important;
  }
  .listitem {
    width: 30px !important;
    margin-bottom: 30px;
  }
  /* Header.css */

  .toggle {
    z-index: 9999999;
    /* color: black !important; */
    background-image: url("../../assets/list.jpeg"); 
    /* Replace with your image path */
    background-size: cover; /* Make sure the image covers the entire button */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    visibility: visible;
    border: none;
    width: 25px; /* Adjust the size as needed */
    height: 25px; /* Adjust the size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Adds a pointer cursor on hover */
  }

  /* Make sure the icon is centered inside the button */
  .toggle .fa-list {
    font-size: 17px;
    color: white; /* Adjust the color as needed */
  }
}

@media screen and (max-width: 500px) {
  .textmehar {
    font-size: 12px !important;
  }
  .listitem {
    width: 30px !important;
    margin-bottom: 30px;
  }
  /* Header.css */

  .toggle {
        margin-bottom: 8px !important;
    z-index: 9999999;
    /* color: black !important; */
    background-image: url("../../assets/list.jpeg"); 
    /* Replace with your image path */
    background-size: cover; /* Make sure the image covers the entire button */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    visibility: visible;
    border: none;
    width: 25px; /* Adjust the size as needed */
    height: 25px; /* Adjust the size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Adds a pointer cursor on hover */
  }

  /* Make sure the icon is centered inside the button */
  .toggle .fa-list {
    font-size: 17px;
    color: white; /* Adjust the color as needed */
  }
}
@media screen and (max-width: 400px) {
  .icon {
    /* display: none; */
    width: 70px;
    
  }
  /* .pp{
    visibility:visible;
  } */
  .textmehar {
    font-size: 9px !important;
  }
  .listitem {
    width: 30px !important;
    margin-bottom: 30px;
  }
  /* Header.css */

  .toggle {
    margin-bottom: 8px !important;
    z-index: 9999999;
    /* color: black !important; */
    background-image: url("../../assets/list.jpeg"); 
    /* Replace with your image path */
    background-size: cover; /* Make sure the image covers the entire button */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    visibility: visible;
    border: none;
    width: 25px; /* Adjust the size as needed */
    height: 25px; /* Adjust the size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Adds a pointer cursor on hover */
  }

  /* Make sure the icon is centered inside the button */
  .toggle .fa-list {
    font-size: 17px;
    color: white; /* Adjust the color as needed */
  }
}
